<template>
    <div class="list-box-within">
        <div class="tuijian-icon" v-if="item.IsExcellent"></div>
        <div class="box-img" :class="{'phone-img':isPhone}" @click="viewVariant(item)">
            <span v-if="showShareName">{{item.ShareUser}}分享</span>
            <list-cover :url='item.Url' :info='item'></list-cover>
        </div>
        <div class="box-text" >
            <el-tooltip popper-class="title-tooltip" v-if="showTips" effect="light" :content="item.Name" placement="top-start">
                <span @click='clickList(item)' v-html="nameFilter(item)" :ref="item.Idx"></span>
            </el-tooltip>
            <span v-else @click='clickList(item)' v-html="nameFilter(item)" :ref="item.Idx"></span>
            <!-- <span v-if="item.IsExcellent" class="promt-res">推荐</span> -->
            <el-popover
                placement="bottom-start"
                width="450"
                trigger="hover">
                <indexing-labeltem :showAll="true" :item="item"></indexing-labeltem>
                <indexing-labeltem :showAll="false" slot="reference" :item="item"></indexing-labeltem>
            </el-popover>
            <!-- <div class='text-name text-keyword' v-if='isValidArray(item.MarkList) && loadAccount().zonename == "271 教育集团"'>
                <div class='text-keyword-item' v-for='(i,index) in item.MarkList' :key='index'>{{ i.Name }}</div>
            </div> -->
            <!-- <div class="text-name clearfix">
                <icon-edit class='icon-edit' :info='item' type='colorful'
                    @on-revoke='revokeClick'
                    @on-resetshare='resetShareClick'
                    @on-share='onShare'
                    @comment-submit='commentSubmit'
                    @on-collected='collectClick'
                    @on-delete='$emit("on-delete")'
                    @onAddBasketClick="onAddBasketClick"
                >
                </icon-edit>
            </div> -->
        </div>
        <sun-icon-edit 
            class="icon-box" 
            :info='item'
            :atid="atid"
            ref="sunIcon"
            @onAddBasketClick="onAddBasketClick"
            @onDelBasketClick="onDelBasketClick"
            @on-collected="collectClick"
            @onBuzhiClick="onBuzhiClick"
            @onPaperStaticClick="onPaperStaticClick"
        ></sun-icon-edit>
    </div>
</template>

<script>

import listCover from '@/components/res/Common/List/ListCover.vue'
// import iconEdit from '@/components/res/Common/Icon/IconEdit.vue'
import IndexingLabeltem from './IndexingLabeltem.vue'
import SunIconEdit from '@/components/res/Common/Icon/SunIconEdit.vue'
export default {
    components: {listCover, IndexingLabeltem,SunIconEdit},
    props:{
        item: {
            type: Object,
            default: {}
        },
        filterType: {
            type: String,
            default: ''
        },
        showCatalog: {
            type: Boolean,
            default: false
        },
        showShareName: {
            type: Boolean,
            default: false
        },
        Platform: {
            type: Object,
            default: {}
        },
        isShowOrigin: {
            type: Boolean,
            default: true
        },
        atid: {
            type: Number,
        },
    },
    filters:{
        // nameFilter(item){
        //     // 获取资源名称
        //     let resName = item.Name
        //     if(item.Url && item.Url != '') {
        //         let url = unescape(decodeURI(item.Url))
        //         let ext = $.trim(url.substring(url.lastIndexOf('.')).toLowerCase())
    
        //         if(ext.indexOf('dcf') >= 0){ // 针对dcf文件将编码过的字符串中的加号转换成 %20
        //             resName = resName.replace(/\+/g, '%20')
        //         }
        //         resName = unescape(decodeURI(resName))
        //         // 获取资源后缀
        //         let resExt = ""
        //         if(ext != 'undefined' && resName.indexOf(ext) < 0){
        //             if(url.indexOf('|') != -1){ // 针对旧资源
        //                 let extStr = url.split('|')[1]
        //                 resExt = '.' + extStr.substring(extStr.lastIndexOf('.') + 1)
        //             }else{
        //                 resExt = ext
        //             }
        //         }
        //         return resName + resExt
        //     } else {
        //         return resName
        //     }
        // }
    },
    mounted(){
        let scrollHeight = this.$refs[this.item.Idx].scrollHeight
        let clientHeight = this.$refs[this.item.Idx].clientHeight
        if(clientHeight<scrollHeight){
            this.showTips = true
        }
    },
    computed:{
        isZiboGqOrYyPlatform() {
            let isZiboGqOrYy = false
            if(this.isValid(this.Platform)) {
                let platformNumber = this.Platform.PlatformSystemNumber
                isZiboGqOrYy = platformNumber == '000006' || platformNumber == '000004'
            }
            return isZiboGqOrYy
        },
        isShowTip() {
            // if(this.$route.path == '/Res/LectureResources/Search') {
            //     if(this.item.Name.length > 42) {
            //         return false
            //     } else {
            //         return true
            //     }
            // } else {
            //     if(this.item.Name.length > 34) {
            //         return false
            //     } else {
            //         return true
            //     }
            // }
            
        },
        isPhone(){
            let adapter = this.getSessionVal('adapter');
            return this.isValid(adapter) && adapter != '' && adapter == 'sunphone'
        }  
    },
    data() {
        return {
            limitLength: 30,
            showTips:false
        }
    },
    methods: {
        viewVariant(item) {
            if (item.timer) clearTimeout(item.timer);

            item.clickCount = item.clickCount ? item.clickCount + 1 : 1;
            item.timer = setTimeout(() => { item.clickCount = 0; }, 1000);

            if (item.clickCount >= 3 && this.$route.query.ispersonalized + '' == 'true') {
                item.clickCount = 0;

                var summary = item.Summary;
                let param = {
                    "module": "bstyl",
                    "papersource": summary.papersource,
                    "paperid": summary.id.id,
                    "paperversion": summary.id.version
                };
                let url = this.getZznScJumpUrl(param);
                window.location.href = url
            }
        },
        nameFilter(item){
            // 获取资源名称
            let resName = item.Name
            if(item.Url && item.Url != '') {
                let url = unescape(decodeURI(item.Url))
                let ext = $.trim(url.substring(url.lastIndexOf('.')).toLowerCase())
    
                if(ext.indexOf('dcf') >= 0){ // 针对dcf文件将编码过的字符串中的加号转换成 %20
                    resName = resName.replace(/\+/g, '%20')
                }
                resName = unescape(decodeURI(resName))
                // 获取资源后缀
                let resExt = ""
                if(ext != 'undefined' && resName.indexOf(ext) < 0){
                    if(url.indexOf('|') != -1){ // 针对旧资源
                        let extStr = url.split('|')[1]
                        resExt = '.' + extStr.substring(extStr.lastIndexOf('.') + 1)
                    }else{
                        resExt = ext
                    }
                }
                return resName
            } else {
                return resName
            }
        },
        //加入资源篮的操作
        onAddBasketClick(item, e) {
            let that = this
            that.$emit('onAddBasketClick', item, e)
        },
        onDelBasketClick(item, e) {
            let that = this
            that.$emit('onDelBasketClick', item, e)
        },
        fileFilledIdx(arr, item) {
            if(this.isValid(item.Url)) {
                let suffix = item.Url.slice(item.Url.lastIndexOf('.') + 1).toLocaleLowerCase()
                let idx = arr.indexOf(suffix)
                return idx
            } else {
                return -1
            }
        },
        addResLook(info, callback){
            return
            let api = this.getWSDomain() + this.getWebServiceURL4('Resource_Look_Insert')
            let param = {
                session: this.getSession(),
                ptIdx: info.TypeIdx,
                rIdx: info.Idx,
                page: 1
            }
            this.addToQueue(api,param,(res)=>{
                callback && callback();
            },null,true)
        },
        clickList(item) {
            let isJustDownFile = ['swf']; // 是否仅进行下载
            if(this.fileFilledIdx(isJustDownFile, item) >= 0){
                this.$nextTick(() => {
                    if(this.$refs.sunIcon){
                        this.$refs.sunIcon.downloadRes()
                    }
                });
            } else {
                this.$emit('open-shadow', item)
            }
            
            //等伟刚
            return
            let device = this.getSessionVal('device')
            let docArr = ['doc','docx','wps','odt','rtf','et','ods','csv','dps','odp','ppt', 'pptx','pps', 'ppsx', 'dcf', 'pdf', 'txt', 'xlsx', 'xls']
            if(this.isValid(device) && (device == 'forclasswin' || device == 'sunclass')) {
                if(item.TypeIdx == 1 || item.TypeIdx == 2) {
                    // this.addResLook(item, ()=>{
                        let env = this.getCurrentEnv()
                        let isdtk = false
                        if(this.isValidArray(item.RSource)){
                            isdtk = item.RSource[0].is_paper_dtk
                        };
                        if(isdtk){
                            let param = {
                                module: "papermanual",
                                session: this.getSession(),
                                paperid: item.Idx,
                                isdtk: isdtk,
                                typeid: item.TypeIdx,
                                show_header: false,
                                origin: escape(location.href)
                            }
                            if(this.getSessionVal('embed')) {
                                param.embed = this.getSessionVal('embed')
                            }
                            if(this.getSessionVal('device')) {
                                param.device = this.getSessionVal('device')
                            }
                            if(env == 'test') {
                                location.href = location.protocol + '//' + 'test.zzn.sun.forclass.net/Home/Gotoview?' + $.param(param)
                            } else {
                                location.href = location.protocol + '//' + 'zzn.sun.forclass.net/Home/Gotoview?' + $.param(param)
                            }
                        }else{
                            let url = location.protocol + '//'
                            if(env == 'test') {
                                url += 'test.zzn.sun.forclass.net/Teacher/ManualPaper2?'
                            }else{
                                url += 'zzn.sun.forclass.net/Teacher/ManualPaper2?'
                            }
                            var param = {
                                paperid: item.Idx,
                                type: item.TypeIdx,
                                origin: escape(location.href)
                            }
                            if(this.getSessionVal('embed')) {
                                param.embed = this.getSessionVal('embed')
                            }
                            if(this.getSessionVal('device')) {
                                param.device = this.getSessionVal('device')
                            }
                            url += $.param(param)
                            location.href = url
                        }
                    // })
                } else if(item.TypeIdx == 0){ // 试题
                    this.$emit('open-detail',item)
                } else {
                    var arr = [{
                        'name':item.Name,
                        'url':item.Url,
                        'size':item.Size,
                        'dataid': item.Idx
                    }];
                    this.sendCPPMessage(arr, 'open' ,null); 
                }
            } else if(device == 'androidPadCKL' && this.fileFilledIdx(docArr, item) >= 0 && !this.isPhone) {
                this.addResLook(item);
                let downloadList = [{
                    subject: '',
                    url: item.Url,
                    fleName: item.Name,
                    encryption: 0,
                    catalog: ''
                }]
                let param = {
                    cmd: 'webPageResDownload',
                    list: downloadList
                }
                HostApp.alert(param)
            } else {
                this.$emit('open-detail',item)
            }
        },
        resetShareClick(){
            this.item.SCRName = '审核中'
            this.item.ctname = '审核中'
            this.item.ShareMode = '校内共享'
            if(this.isValidArray(this.RSource)){
                this.item.RSource[0].ShareName = '校内共享'
            }
        },
        onShare(info){
            this.$emit("on-share",info)
        },
        revokeClick(){
            if(this.item.TypeIdx == 8){
                this.item.ctname = '已发布'
                this.item.SCRName = '未审核'
                this.item.ShareMode = '未共享'
            }else if(this.item.TypeIdx == 5 || this.item.TypeIdx == 9){
                this.item.ctname = '未审核'
                this.item.ShareMode = '未共享'
            }else if(this.item.TypeIdx == 0 || this.item.TypeIdx == 2){
                this.item.ctname = '已发布'
                this.item.ShareMode = '未共享'
            }
        },
        getLength(str) {
          let realLength = 0, len = str.length, charCode = -1
          for (let i = 0; i < len; i++) {
            charCode = str.charCodeAt(i);
            if (charCode >= 0 && charCode <= 128)
               realLength += 1;
            else
               realLength += 2;
          }
          return realLength
        },
        checkSize(val){
            if(val){
                if(val.split(',').length == 1){
                    return val
                }else{
                    let arr = val.split(',')
                    let num = 0
                    arr.forEach((key,val) => {
                        if(key.split(' ')[1] == 'KB'){
                            num += Number(key.split(' ')[0])
                        }
                    })
                    return num.toFixed(2) + ' KB'
                }
            }
        },
        getRelativeLengthStr(str,len){
            let arr = str.split('')
            let originLength = this.getLength(str)
            len = len <= originLength ? len : originLength
            let newArr = new Array()
            let realLength = 0,charCode = -1
            for(let i=0;i<arr.length;i++){
                charCode = str.charCodeAt(i)
                if (charCode >= 0 && charCode <= 128){
                    realLength += 1
                }else{
                    realLength += 2
                }
                if(realLength <= len){
                    newArr.push(arr[i])
                }else{
                    break
                }
            }
            return newArr.join('')
        },
        organization(origin){
            if(this.isValid(origin) && origin != ''){
                let length = this.getLength(origin)
                if(length > this.limitLength){
                    origin = this.getRelativeLengthStr(origin,this.limitLength) + '...'
                }
                return origin
            }else{
                return 'default zone'
            }
        },
        showname(name){
            if(this.isValid(name) && name != ''){
                let length = this.getLength(name)
                if(length > this.limitLength){
                    name = this.getRelativeLengthStr(name,this.limitLength) + '...'
                }
                return name
            }else{
                return '自由资源'
            }
        },
        collectClick(status){
            this.item.IsCollected = status
            this.$emit('collectClick')
        },
        commentSubmit(starNumber){
            this.item.Star = starNumber
        },
        onBuzhiClick(info){
            this.$emit('onBuzhiClick',info)
        },
        onPaperStaticClick(info){
            this.$emit('onPaperStaticClick',info)
        }
    }
}
</script>

<style scoped>
.list-box-within{
    display: flex;
    justify-content: space-between;
}
.box-img{
    width: 65px;
    text-align: center;
    line-height: 70px;
    position: absolute;
}
.box-img span{
    color: #A0A9C1;
    display: inline-block;
    margin: 8px 0 12px;
}
.box-text{
    /* width: calc(100% - 430px); */
    box-sizing:border-box;
    height: 70px;
    display: flex;
    align-items: center;
    margin-left: 80px;
}
.box-text span{
    font-size: 16px;
    margin-bottom: 8px;
    cursor: pointer;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space:pre-wrap;
    word-break:normal;
}
.text-fire{
    line-height: 24px;
}
.text-catalog{
    width: 80%;
    height: 24px;
    overflow: hidden;
}
.text-name{
    font-size: 14px;
    color: #A0A9C1;
    position: relative;
}
.text-name-wrap{
    float: left;
}
.rate{
    font-size: 18px;
}
.text-icon{
    position: relative;
    top: 2px;
}
.no-result-img{
    margin: 0 auto;
    display: block;
}
.spin-icon-load{
    width:200px;
}
.spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
.spin-col{
    width: 100%;
    height: 800px;
    position: relative;
    border: 1px solid #eee;
}
.icon-edit{
    float: right;
    background: white;
    margin-top: -40px;
}
.text-keyword-item{
    border: 1px solid #D4DFE7;
    display: inline;
    padding: 2px 6px;
}
.splite-line{
    border-left: 1px solid #757070;
    width: 1px;
    height: 14px;
    margin: 4px 14px;
}
.res-name-line{
    color: #757070;
    font-size: 16px;
}
.no-pass-reason{
    width: 300px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cart {
    position: absolute;
    left: 30px;
    bottom: 30px;
    width: 100px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    border: 1px solid #ccc;
}
.promt-res{
    color: #f16c1a;
    font-size: 15px;
    margin-left: 14px;
    display: inline-block;
    vertical-align: top;
}
.phone-img{
    width: auto !important;
    min-width: 20%;
}
.list-box-within{
    position: relative;
    overflow: hidden;
}
.tuijian-icon{
    background-image: url('/static/images/res/sun-kczx/kczx-tuijian.png');
    min-width: 40px;
    width: 40px;
    height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
}
.icon-box{
    /* position:absolute;
    right: 0; */
    margin-left: 20px;
}

</style>
<style>
.title-tooltip{
  	background: rgba(74,74,74,0.80) !important;
    border-radius: 2px !important;
    color: #FFF !important;
    font-size:	12px；
}
.el-tooltip__popper.is-light{
    border: none !important;
}
.el-tooltip__popper .popper__arrow{
    display: none;
}
</style>
