<template>
    <div>
        <div class="cover" v-if="Visable" @click="onCoverClicked"></div>
        <div class="basket-icon" @click="onBaketDivClick" @mousedown="move">
            <img class="img-basket" :class="{'img-tranform': transitionBoo}"  src="/static/images/res/sun-kczx/new-basket-icon.png" alt="">
            <div class="icon-word">资源篮</div>
            <div class="icon-number">{{  resAmount  }}</div>
        </div>
        <transition name="slide-fade">
            <!--  v-clickoutside="onHideSearchClick" -->
            <div class="basket-block" v-if="Visable">
                <div class="top-block">
                    <!-- <span class="line-word"></span> -->
                    <span class="all-word">资源篮</span>
                    <span class="number">（{{ resAmount }}）</span>
                </div>
                <div class="close-btn" @click="onBaketDivClick"><Icon type="ios-close-empty"></Icon></div>
                <div class="basket-list" v-if="isValidArray(basketList) || resAmount > 1">
                    <div class="list-container">
                        <CheckboxGroup v-infinite-scroll="loadMore" :infinite-scroll-immediate="false" class="loadMore" v-model="checkedList" @on-change="handleCheckedFilesChange">
                            <Checkbox v-for="(item, idx) in basketList" :key="idx" :label="item">
                                <div class="res-item clearfix">
                                    <div class="res-cover" @click.stop="onFileClick(item, $event)">
                                        <!-- <img class="cover-img" :src="item.Url" v-if="imgArr.indexOf(item.suffix) >= 0" alt=""> -->
                                        <span class="cover-img" :style="{ 'background-image': 'url(' + getFileBac(item) +')'}"></span>   
                                    </div>
                                    <div class="res-name" @click.stop="onFileClick(item, $event)">
                                        <el-tooltip :disabled="item.Name.length > 20 ? false : true" :content="item.Name" placement="top-start">
                                            <span class="file-name" :class="{'refenced-name': item.IsRefenced }">{{ item.Name || '未知' }}</span>
                                        </el-tooltip>
                                        <img class="is-me-create" v-if="item.IsMeCreate" src="/static/images/res/is_me_upload.png" alt="">
                                    </div>
                                    <div class="res-operate pull-right">
                                        <span class="basket-icon-item" @click.prevent="onDeleteClick(item, idx)"></span>
                                        <span v-if="getResType(item)" class="basket-icon-item download-icon" @click.prevent="onDownloadClick(item)"></span>
                                    </div>
                                </div>
                            </Checkbox>
                            <!-- <div class="noMoreData">无更多数据</div> -->
                        </CheckboxGroup>
                    </div>
                    <!-- <div class="page sun-basket-page">
                        <Page size="small" :total="basketPageInfo.total" :page-size='basketPageInfo.pageSize' :current='basketPageInfo.current' @on-change='changePage'></Page>
                    </div> -->
                </div>
                <div class="no-data" v-else>
                    <img src="/static/images/res/empty_basket.png" alt="">
                </div>
                <div class="operate-block clearfix" v-show="isValidArray(basketList) || resAmount > 1">
                        <!-- <div class="pull-left">
                            <Checkbox :indeterminate="isIndeterminate" :value="checkAll" @click.prevent.native="handleCheckAllChange">全选当前页</Checkbox>
                        </div> -->
                    <div class="top-operate clearfix">
                        <div class="btns-box">
                            <button class="btns" @click="onBranchClick('清空')">清空</button>
                            <button class="btns"  @click="onBranchClick('批量删除')">删除</button>
                            <button class="btns" @click="onBranchClick('下载')">下载</button>
                            <!-- <Button type="ghost" shape="circle" @click="onBranchClick('分享')">分享</Button> -->
                        </div>
                    </div>
                    <!-- <div class="bottom-operate pull-right">
                        <template v-if="isValid(device) && device == 'androidPadCKL'">
                            <Button type="ghost" shape="circle" @click="onForclasswinUpload">本地上传</Button>
                        </template>
                        <div v-if="isValidInt(pageConfig.total, 29)">
                            <Button slot="uploadBtn" type="ghost" shape="circle" @click="onExceedBasket">本地上传</Button>
                        </div>
                        <upload-file v-else @successUpload="successUpload">
                            <Button slot="uploadBtn" type="ghost" shape="circle">本地上传</Button>
                        </upload-file>
                        <Button type="ghost" shape="circle" @click="onBuildAnswerCardClick">建答题卡</Button>
                        <Button type="ghost" shape="circle" @click="onReadyClassClick">去备课</Button>
                    </div> -->
                </div>
            </div>
        </transition>
        <query-operate-modal :modalConfig="queryConfig" @onQueryClick="onQueryClick"></query-operate-modal>
        <!-- <modal-share ref='modalShare' :modal_S='modal_S' :info='shareInfo' @shareSuccess='shareSuccess'></modal-share> -->
        <!-- <build-answer-sheet-modal :modalConfig="answerSheetConfig"></build-answer-sheet-modal> -->
    </div>
</template>
    
<script>
import QueryOperateModal from '@/components/res/Common/Modal/QueryOperateModal.vue'
// import BuildAnswerSheetModal from '@/components/res/Common/Modal/BuildAnswerSheetModal.vue'
// import modalShare from '@/components/res/Common/Modal/ModalShare/ModalShare.vue'
// import UploadFile from '@/components/cm/research/Common/Upload.vue'

const clickoutside = {
    bind(el, binding, vnode) {
        function documentHandler(e) {
            if(el.contains(e.target)) {
                return false
            }
            if(binding.expression) {
                binding.value()
            }
        }

        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    update() {},
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
}

export default {
    name: "",
    props: {
        list: {
            type: Array
        },
        clearBasketList: {
            type: Array
        },
        basketPageInfo:{}
    },
    directives: {clickoutside},
    components: {
        QueryOperateModal,
    },
    data () {
        return {
            Visable: false,
            basketList: [],
            checkedList: [],
            pageConfig: {
                index: 1,
                count: 10,
                total: 0
            },
            isIndeterminate: false,
            checkAll: false,
            imgArr:['jpg',"jpeg", 'jpeg', 'gif', 'png', 'bmp', 'tif'],
            audioArr:['mp3', 'm4a', 'mid', 'wma','wav', 'ogg'],
            videoArr:["mp4",'yoya','flv','wmv','flv','mkv'],
            docArr:['doc','docx','wps','odt','rtf','et','ods','csv','dps','odp'],
            tableArr:['xls','xlsx','xlt','xltx'],
            queryConfig: {
                visable: false,
                dialogItem: '',
                operateLabel: '',
                operateItem: null
            },
            modal_S: {
                status: false
            },
            shareInfo: {},
            transitionBoo: false,
            answerSheetConfig: {
                visable: false
            },
            resAmount:0,
            notDownloadType:['m4a','amr','mp3','wma','3gp','wmv','mpeg','mov','mp4','rmvb','qsv','flv','avi'],
            isMouseClick:false
        };
    },
    watch: {
        list() {
            this.initBasketData()
            this.resAmount = this.list.length-2
        },
        basketList(){
            this.resAmount = this.basketList.length
        },
        basketPageInfo(nVal){
            // this.pageConfig.total = nVal.total
            // this.pageConfig.index = nVal.current
            // this.pageConfig.count = nVal.pageSize
        }
    },
    computed:{
    },
    methods: {
        onHideSearchClick() {
            this.Visable = false
        },
        // 资源下载预览
        onFileClick(item, e) {
            this.preventDefault(e)
            // 若为 swf 文件则直接下载
            if(item.suffix.indexOf('swf') > -1){
                this.onDownloadClick(item);
                return
            }
            if(!item.Summary){
                this.$emit('open-detail', item)
            }else{
                //改为直接跳转路由
                let id = item.Summary.id.id
                let version = item.Summary.id.version
                let paperSource = item.Summary.paperSource
                let param = {
                    "module": "papermanual",
                    "papersource": paperSource,
                    "paperid": id,
                    "contenttype": 1,
                    "paperversion": version,
                    "readonly":true
                }    
                let url = this.getZznScJumpUrl(param)
                window.location.href = url
            }
            return
            this.preventDefault(e)
            let device = this.getSessionVal('device')
            if(this.isValid(device) && (device == 'forclasswin' || device == 'sunclass')) {
                if((item.TypeIdx == 1 || item.TypeIdx == 2) && !this.isValid(item.Url)) {
                    let env = this.getCurrentEnv()
                    let param = {
                        module: "papermanual",
                        session: this.getSession(),
                        paperid: item.Idx,
                        isdtk: this.isValidArray(item.RSource) ? item.RSource[0].is_paper_dtk : false,
                        typeid: item.TypeIdx,
                        'show_header': false,
                        origin: escape(location.href)
                    }
                    if(this.getSessionVal('embed')) {
                        param.embed = this.getSessionVal('embed')
                    }
                    if(this.getSessionVal('device')) {
                        param.device = this.getSessionVal('device')
                    }
                    if(env == 'test') {
                        location.href = location.protocol + '//' + 'test.zzn.sun.forclass.net/Home/Gotoview?' + $.param(param)
                    } else {
                        location.href = location.protocol + '//' + 'zzn.sun.forclass.net/Home/Gotoview?' + $.param(param)
                    }
                } else {
                    var arr = [{
                        'name':item.Name,
                        'url':item.Url,
                        'size':item.Size,
                        'dataid': item.Idx
                    }];
                    this.sendCPPMessage(arr, 'open' ,null); 
                }
            } else {
                this.$emit('open-detail',item)
            }
        },
        // 资源篮是否显示
        onBaketDivClick() {
            if(!this.isMouseClick)return
            this.Visable = !this.Visable
            // this.$bus.emit('onBaketDivClick')
        },
        onCoverClicked(){
            this.Visable = false
        },
        // 资源篮删除资源
        delAccountCandidateResource(arr) {
            let param = []
            arr.forEach(element => {
                param.push(element.Idx)
            });
            let api = this.getResourceCenterBusinessUrl()+ '/api/ResourceBasketService/BatchCancel'
            this.startLoading()
            let that = this
            this.$http.post(api, param).then((res) => {
                this.stopLoading()
                if (res.data.status.code == 200){
                    that.$emit('deleteCandidateSuccess',param)
                    setTimeout(() => {
                        for (let i = 0; i < param.length; i++) {
                            const element = param[i];
                            this.$bus.emit('onCartDelSuccess',element)
                        }
                    }, 500);
                    
                }else{

                }
            })
        },
        // 资源篮上传资源
        uploadAccountCandidateResource(info) {
            let arr = []
            arr.push({
                PaperName: info.Name,
                FileUrl: info.Url,
                Size: info.Size
            })
            let param = {
                session: this.getSession(),
                resList: JSON.stringify(arr)
            }
            this.addToQueue(this.getUrlRes('UploadAccountCandidateResource'), param, (result) => {
                if(this.isValidArray(result)) {
                    this.$emit('updateCandidateData')
                }
            }, null, true)
        },
        initBasketData() {
            if(this.list.length > 2) {
                this.pageConfig.total = Number(this.list[0].Total)
                this.basketList =  this.list.slice(2)
                for(let prop of this.basketList) {
                    let suffix = ''
                    if(this.isValid(prop.Url)) {
                        let idx = prop.Url.lastIndexOf('.')
                        suffix = prop.Url.slice(idx + 1)
                    }
                    this.$set(prop, 'suffix', suffix)
                }
            } else {
                this.basketList = []
            }
        },
        changePage(idx) {
            this.checkedList = []
            this.checkAll = false
            this.isIndeterminate = false
            this.pageConfig.index = idx
            this.$emit('onChangePage', idx)
        },
        getFileBac(item) {
            let path = '/static/images/res/icon/', type = '', isDtk = false, typeIdx = ''
            if(this.isValid(item)) {
                if(this.isValidArray(item.RSource)) {
                    isDtk = item.RSource[0].is_paper_dtk
                }
                typeIdx = item.TypeIdx
            }
            // 数字试题
            if(typeIdx == 0) {
                path += 'szst.png'
                return path
            }
            // 数字试卷
            if(typeIdx == 1 || typeIdx == 2) {
                if(this.isValid(item.res_type) && item.res_type == 'sc') {
                    path += 'icon_resimg_sunclass_paper3.png'
                    return path
                }
                // 是否是答题卡
                if(isDtk) {
                    path += 'dtk.png'
                    return path
                }
                path += 'szsj.png'
                return path
            }
            // 教研资源
            if(typeIdx == 8) {
                path += 'jy.png'
                return path
            }
            // 学习设计
            if(typeIdx == 11) {
                path += 'design.png'
                return path
            }
            if(this.isValid(item.Url) && item.Url != '') {
                if(this.isValid(item.res_type) && item.res_type == 'sc') {
                    // if(item.suffix == 'dcf') {
                    //     path = '/static/images/res/icon/icon_resimg_sunclass_paper3.png'
                    // } else {
                        path = this.transferSuffix(item.suffix)
                    // }
                } else {
                    path = this.transferSuffix(item.suffix)
                }
            } else {
                let name_idx = item.Name.lastIndexOf('.')
                let name_suffix = item.Name.slice(name_idx + 1)
                if(this.isValid(item.res_type) && item.res_type == 'sc' && name_suffix != 'dcf') {
                    path = '/static/images/res/icon/icon_resimg_sunclass_paper3.png'
                } else {
                    path = this.transferSuffix(item.suffix)
                }
            }
            return path
        },
        transferSuffix(suffix) {
            let ext = suffix.toLowerCase()
            let path = '/static/images/res/icon/'
            if(this.imgArr.indexOf(ext) != -1){
                path += 'png.png'
            }else if(this.docArr.indexOf(ext) != -1){
                path += 'word.png'
            }else if(ext == 'ppt' || ext == 'pptx' || ext == 'pps' || ext == 'ppsx'){
                path += 'PPT.png'
            }else if(ext == 'pdf'){
                path += 'PDF.png'
            }else if(this.audioArr.indexOf(ext) != -1){
                path += 'mp3.png'
            }else if(this.videoArr.indexOf(ext) != -1){
                path += 'video.png'
            }else if(this.tableArr.indexOf(ext) != -1){
                path += 'xls.png'
            }else if(ext == 'dcf'){
                path += 'dcf.png'
            }else if(ext == 'txt'){
                path += 'TXT.png'
            }else if(ext == 'apk'){
                path += 'apk.png'
            }else if(ext == 'zip'){
                path += 'zip.png'
            }else if(ext == 'rar'){
                path += 'rar.png'
            }else if(ext == 'swf' ){
                path += 'flash.png'
            }else{
                path += 'otherfile.png'
            }
            console.log(ext);
            return path
        },
        handleCheckAllChange() {
            if (this.isIndeterminate) {
                this.checkAll = false
            } else {
                this.checkAll = !this.checkAll
            }
            this.isIndeterminate = false
            if (this.checkAll) {
                this.checkedList = this.basketList
            } else {
                this.checkedList = []
            }
        },
        handleCheckedFilesChange(data) {
            if(data.length == this.basketList.length) {
                this.checkAll = true
                this.isIndeterminate = false
            } else if(data.length > 0) {
                this.isIndeterminate = true
                this.checkAll = false
            } else {
                this.isIndeterminate = false
                this.checkAll = false
            }
        },
        onExceedBasket(){
            this.$Notice.warning({
                title: '提示',
                desc: '资源篮数量已达上限',
                duration: 2
            })
        },
        onForclasswinUpload() {
            var total = this.pageConfig.total - 0
            if(total >= 30){
                this.$Notice.warning({
                    title: '提示',
                    desc: '资源篮数量已达上限',
                    duration: 2
                })
            }else{
                this.sendCPPMessage(null,'upload',null,(data)=>{
                    this.basketList.push({
                        name: data.name,
                        size: data.size,
                        url: data.url
                    })
                },true,10)
            }
        },
        // 成功上传回调函数
        successUpload(info) {
            this.uploadAccountCandidateResource(info)
        },
        // 弹框确认操作
        onQueryClick() {
            if(this.queryConfig.operateLabel == '删除') {
                let arr = [this.queryConfig.operateItem]
                this.delAccountCandidateResource(arr)
            }
            if(this.queryConfig.operateLabel == '批量删除') {
                this.delAccountCandidateResource(this.checkedList)

            } else if(this.queryConfig.operateLabel == '清空') {
                let api = this.getResourceCenterBusinessUrl()+ '/api/ResourceBasketService/ClearByUserId'
                this.$http.post(api,{}).then(res=>{
                    console.log(res);
                    if(res.data.data){
                        this.basketList = []
                        this.clearBasketList = []
                        this.$bus.emit('onCartCleared')
                    }
                })

                // let api = this.getResourceCenterBusinessUrl()+ '/api/ResourceBasketService/GetResourceBasketByUserId'
                // let delList = []
                // this.$http.post(api,{}).then(res=>{
                //     let data = []
                //     if(res.data.data){
                //         data = res.data.data
                //         data = this.toUpperCase(data)
                //         for (let i = 0; i < data.ResourceData.length; i++) {
                //             data.ResourceData[i] = this.toUpperCase(data.ResourceData[i])
                //         }
                //     }
                //     if(this.isValidArray(data.ResourceData)){
                //         data.ResourceData.forEach(item=>{
                //             delList.push({Idx:item.ResourceDataId})
                //         })
                //         this.delAccountCandidateResource(delList)
                //     }
                // })
                // this.delAccountCandidateResource(this.clearBasketList)
                // this.basketList = []
                // this.clearBasketList = []

            }
            this.checkedList = []
            this.checkAll = false
            this.isIndeterminate = false
        },
        // 下方的批量操作  删除， 下载，分享， 清空
        onBranchClick(label) {
            this.queryConfig.operateLabel = label
            if(label == '清空') {
                if(this.basketList.length == 0) {
                    this.$Notice.warning({
                        title: '提示',
                        desc: '请先添加资源到资源篮哦~',
                        duration: 2
                    })
                } else {
                    this.queryConfig.visable = true
                    this.queryConfig.dialogItem = '确认移出资源篮中所有资源？'
                }
            } else {
                if(this.isValidArray(this.checkedList)) {
                    if(label == '下载') {
                        let noDownloadStatus = false
                        let urlList = []
                        let nameList = []
                        for(let prop of this.checkedList) {
                            if(this.getResType(prop)){
                                urlList.push(prop.Url)
                                nameList.push(prop.Name)
                            }else{
                                noDownloadStatus = true
                            }
                        }
                        this.getAccountDownloadPermission(urlList, nameList)
                        setTimeout(() => {
                            if(noDownloadStatus) {
                                this.$Notice.warning({
                                    title: '提示',
                                    desc: '在线作业、mp3、wma、mp4、mpge4类型文件不支持下载！',
                                    duration: 4
                                })
                            }
                        }, 500);
                    }
    
                    if(label == '批量删除') {
                        this.queryConfig.visable = true
                        this.queryConfig.dialogItem = '确认移出所选资源？'
                    }
    
                    if(label == '分享') {
                        this.$refs.modalShare.getSharedList()
                        this.modal_S.status = true
                        let arr = []
                        for (let prop of this.checkedList) {
                            arr.push({
                                TypeIdx: prop.TypeIdx,
                                Idx: prop.Idx
                            })
                        }
                        this.shareInfo = arr
                    }
                    
                } else {
                    this.$Notice.warning({
                        title: '提示',
                        desc: '请先选择资源',
                        duration: 2
                    })
                }
            }
        },
        initCheckboxStatus() {
            this.checkedList = []
            this.isIndeterminate = false
            this.checkAll = false
        },
        // 分享成功后的回调
        shareSuccess() {
            this.initCheckboxStatus()
        },
        // 删除
        onDeleteClick(item, idx) {
            this.queryConfig.operateLabel = '删除'
            this.queryConfig.visable = true
            this.queryConfig.dialogItem = '是否确认删除所选资源？'
            this.queryConfig.operateItem = item
        },  
        // 下载
        onDownloadClick(item) {
            this.getAccountDownloadPermission([item.Url], [item.Name])
        },
        getAccountDownloadPermission(url, name) {
            let device = this.getSessionVal('device');
            url = url.map(r => decodeURIComponent(r));
            if(this.isValid(device) && device != '') {
                if(device == 'forclasswin' || device == 'sunclass'){
                    let arr = []
                    url.forEach((item,i)=>{
                        let obj = {
                            'name': name[i],
                            'url': url[i],
                            'source': null,
                            'size': null,
                            'dataid': 0
                        }
                        arr.push(obj)
                    })
                    this.sendCPPMessage(arr, 'SunWinBatch', null)
                }else if(device == 'androidPadCKL'){
                    let downloadList = [{
                        subject: '',
                        url: url,
                        fleName: name,
                        encryption: 0,
                        catalog: ''
                    }]
                    let param = {
                        cmd: 'webPageResDownload',
                        list: downloadList
                    }
                    HostApp.alert(param)
                }
            } else {
                if(url.length>1){
                    this.batchDownload(url,name)
                }else if(typeof url === 'string'){
                    window.location.href = url
                }
            }
        },
        batchDownload(urlList,nameList){//批量下载
            const files = urlList
            for (let i = 0; i < files.length; i++) {
                const url = files[i]
                const elem = document.createElement('iframe');
                elem.style.display = 'none';
                elem.src = url;
                document.body.appendChild(elem)
                setTimeout(() => {
                    document.body.removeChild(elem)
                }, 1000);
            }

        },
        // 去建答题卡
        onBuildAnswerCardClick() {
            // this.answerSheetConfig.visable = true
            if(this.pageConfig.total >= 30) {
                this.$Notice.warning({
                    title: '提示',
                    desc: '资源篮资源数目已达上限！',
                    duration: 2
                })
            } else {
                let env = this.getCurrentEnv()
                let param = {
                    module: 'cjdtk',
                    show_header: false,
                    session: this.getSession(),
                    originurl: escape(location.href)
                }
                if(this.getSessionVal('embed')) {
                    param.embed = this.getSessionVal('embed')
                }
                if(env == 'test') {
                    window.location.href = location.protocol + '//' + 'test.zzn.sun.forclass.net/Home/Gotoview?' + $.param(param)
                } else {
                    window.location.href = location.protocol + '//' + 'zzn.sun.forclass.net/Home/Gotoview?' + $.param(param)
                }
            }
            
        },
        // 去备课
        onReadyClassClick() {
            let env = this.getCurrentEnv(), path = ''
            let speriod = this.getLocalVal('speriod')
            let param = {
                show_header:  false,
                period: speriod,
                session: this.getSession(),
                module: 'bzzysun'
            }
            if(this.getSessionVal('embed')) {
                param.embed = this.getSessionVal('embed')
            }
            if(this.getSessionVal('device')) {
                param.device = this.getSessionVal('device')
            }
            if(env == 'test') {
                path = location.protocol + '//' + 'test.zzn.sun.forclass.net/Home/Gotoview?' + $.param(param)
            } else {
                path = location.protocol + '//' + 'zzn.sun.forclass.net/Home/Gotoview?' + $.param(param)
            }
            let device = this.getSessionVal('device')
            if(this.isValid(device) && device != '' && device != 'androidPadCKL') {
                let cppParam = {
                    'url': path,
                    'target': '教学中心'
                };
                let $xml = $('<skip>', cppParam);
                top.CallCPP($xml.prop('outerHTML'));
            } else {
                window.location.href = path
            }
        },
        loadMore(){
            console.log('onBasketScroll');
            this.$emit('onBasketScroll')
        },
        getResType(info){
            if(info.TypeIdx != 3) return false
            if(info.Url){
                let type = info.Url.split('.')
                type = type[type.length-1].toLowerCase()
                if(this.notDownloadType.indexOf(type)!=-1){
                    return false
                }else{
                    return true
                }
            }else{
                return true
            }
        },
        move(e){
            let that = this
            let odiv = $('.basket-icon')[0]    //获取目标元素
            let oldY = odiv.offsetTop
            //算出鼠标相对元素的位置
            let disY = e.clientY - odiv.offsetTop;
            let top = 0
            document.onmousemove = (e)=>{       //鼠标按下并移动的事件
                //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                top = e.clientY - disY;
                //移动当前元素
                top > 0 ? top : top = 0 //超出顶部限制

                let allTop = top + $('.basket-icon').height()
                allTop > document.documentElement.clientHeight //超出底部限制
                ?
                top = document.documentElement.clientHeight - $('.basket-icon').height()
                :
                top 
                odiv.style.top = top + 'px';
            };
            document.onmouseup = (e) => {
                let newY = odiv.offsetTop
                that.$emit('onBasketIconMoved',newY - oldY)
                that.isMouseClick =  top == 0
                document.onmousemove = null;
                document.onmouseup = null;
            };
        }    
    },
    mounted() {
        this.$bus.on('onMenuTopClick', () => {
            this.Visable = false
        })

    }
}
</script>
    
<style lang="css" scoped>
    .basket-icon{
        position: fixed;
        z-index:10;
        right: 0.4%;
        bottom: 25%;
        width: 84px;
        text-align: center;
        color: #B78114;
        font-size: 12px;
        padding-top: 6px;
        cursor: pointer;
        height: 64px;
        background-color: #fff;
        background-repeat: no-repeat;
        background-size: 34px 30px;
        background-position: center 10px;
        box-sizing: border-box;
        box-shadow: 0 1px 6px 1px rgb(0 0 0 / 10%);
        border-radius: 100px 0 0 100px;
        padding-left: 5px;
        user-select: none;
    }
    .img-basket{
        width:36px;
         -webkit-user-drag: none;
    }
    .icon-word{
        margin-top: 2px;
    }
    .basket-block > img{
        display: inline-block;
        width: 32px;
        height: 32px;
    }
    .icon-number{
        position: absolute;
        right: 14px;
        top: 4px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        line-height: 16px;
        font-size: 12px;
        text-align: center;
        background-color: #FF5A5A;
        color: #fff;
    }
    .basket-block{
        position: fixed;
        z-index:10;
        right: 0;
        top: 30px;
        color: #A0A9C1;
        background: #FFFFFF;
        box-shadow: rgba(1, 11, 16, 0.15) 0px 3px 57px 8px;
        border-radius: 4px;
        width: 520px;
        /* height: 80vh; */
        /* height: 663px; */
        padding: 20px 0 0;
    }
    .close-btn{
        position: absolute;
        right: 25px;
        top: 4px;
        font-size: 39px;
    }
    .top-block{
        font-size: 18px;
        border-bottom: 1px solid #E3E3E3;
        padding-bottom: 10px;
        position: relative;
        /* padding-left: 10px; */
        margin: 0 auto;
        text-align: center;
    }
    .top-block .all-word{
        color: #4A4A4A;
    }
    .top-block .number{
        font-size: 16px;
        color: #22B5AD;
    }
    .btns-box{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .btns-box .btns{
        color: #757575;
        margin-left: 6px;
        background: #fff;
        width: 70px;
        height: 26px;
        line-height: 0.5;
        border-radius: 4px;
        border: #dddee1 1px solid;
    }
    .btns-box button:first-child{
        border: #E55062 1px solid;
        color: #E55062;
    }
    .basket-list{
        padding: 0 0px 0px 20px;
        /* height: 410px; */
        margin-bottom: 80px;
    }
    .list-container{
        /* height: 368px; */
        height: 40vh;
        /* max-height: 40vh; */
        min-height: 28vh;
        /* overflow-y: auto; */
        padding-right: 10px;
        margin-top: 10px;
        overscroll-behavior: contain;
    }
    .loadMore{
        overflow: auto;
        height: 100%;
    }
    .loadMore::-webkit-scrollbar {
        width: 4px;
    }
    .loadMore::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #d8d8d8;
    }
    .list-container::-webkit-scrollbar {
        width: 4px;
    }
    .list-container::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #d8d8d8;
    }
    .list-container::-webkit-scrollbar-track {
        border-radius: 10px;
        background: #e5eaf7;
        border-right:1px solid #EBEEF5;
    }
    .line-word{
        position: absolute;
        left: 0;
        top: 3px;
        width: 2px;
        height: 14px;
        display: inline-block;
        background:#4EC4F5;
    }
    .res-cover, .res-name, .res-operate, .res-item{
        display: inline-block;

    }
    .refenced-name{
        color: #0082C9;
    }
    .res-operate{
        padding-top: 5px;
    }
    .basket-list /deep/ .ivu-checkbox-group-item{
        display: block;
        color: #1B2A51;
        font-size: 15px;
        margin-right: 0px;
        width: 100%;
        height: 52px;
        padding-left: 1px;
        line-height: 52px;
        /* border-bottom: 1px dashed #E3E3E3; */
        margin-bottom: 20px;
        padding-right: 10px;
    }
    .basket-list /deep/ .ivu-checkbox-checked .ivu-checkbox-inner{
        border-color: #2DCCC4 !important;
        background-color: #2DCCC4 !important;
    }
    .operate-block /deep/ .ivu-checkbox-indeterminate .ivu-checkbox-inner {
        border-color: #2DCCC4 !important;
        background-color: #2DCCC4 !important;
    }
    .res-item{
        width: calc(100% - 22px);
    }
    .res-cover{
        display: inline-block;
        width: 35px;
        height: 35px;
        margin: 0 18px 0 16px;
        vertical-align: text-bottom;
    }
    .res-name{
        vertical-align: middle;
        width: calc( 100% - 130px);
    }
    .res-name span, .res-name img{
        display: inline-block;
    }
    .res-name span{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 48px);
    }
    .res-name img{
        margin-left: 12px;
        margin-top: -40px;
    }
    .file-name{
        font-weight: normal;
    }
    .res-cover .cover-img{
        display: block;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        transform: scale(1.4);
    }
    .is-me-create{
        display: inline-block;
    }
    .basket-icon-item{
        display: inline-block;
        width: 22px;
        height: 22px;
        background-image: url('/static/images/res/sun-kczx/basket-edit.png');
        background-position: -2px 0px;
        background-size: 100px;
        background-repeat: no-repeat;
    }
    .basket-icon-item:hover{
        background-position-x:-27px;
    }
    .download-icon{
        background-position: -2px -27px;
        margin-left: 16px;
    }
    .page{
        width: 100%;
        text-align: center!important;
    }
    .sun-basket-page{
        padding: 0px!important;
        margin: 13px 0;
    }
    .operate-block{
        /* background-color: #F4F7FA; */
        padding: 20px;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
    }
    .operate-block /deep/ .ivu-btn-ghost{
        color: #757575;
        margin-left: 6px;
        background: #fff;
        width: 70px;
        height: 26px;
        line-height: 0.5;
    }
    .operate-block /deep/ .ivu-checkbox-checked .ivu-checkbox-inner{
        border-color: #2DCCC4 !important;
        background-color: #2DCCC4 !important;
    }
    .bottom-operate  /deep/ .ivu-btn-ghost{
        width: 90px;
    }
    .bottom-operate{
        margin-top: 18px;
    }
    .bottom-operate > div{
        display: inline-block;
    }
    .img-tranform{
        animation: aa 0.3s linear infinite;
    }
    .no-data{
        width: 100%;
        text-align: center;
        padding-top: 45px;
        height: 410px;
    }
    .list-container /deep/ .ivu-checkbox{
        vertical-align: super;
    }
    .file-name:hover{
        color: #2DCCC4;
    }
    @-webkit-keyframes aa {
        0%{
            transform: rotate(25deg);
        }
        25%{
            transform: rotate(0deg);
        }
        50%{
            transform: rotate(-25deg);
        }
        100%{
            transform: rotate(0deg);
        }
    }
    .noMoreData{
        text-align: center;
    }
    .cover{
        position: fixed;
        top: 0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
</style>