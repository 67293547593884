<!--
 * @Author: your name
 * @Date: 2021-03-23 17:42:12
 * @LastEditTime: 2021-04-20 16:10:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\Common\Modal\QueryOperateModal.vue
-->
<template>
    <div class="dialog">
        <Modal
        title="提示"
        v-model="modalConfig.visable"
        @on-ok="onQueryClick"
        @on-cancel="onCancelOperateClick"
        class="vertical-center-modal"
        width="30%">
            <div class="is-delete">{{ modalConfig.dialogItem }}</div>
        </Modal>
    </div>
</template>
    
<script>
export default {
    name: "",
    props:{
        modalConfig: {
            type: Object
        }
    },
    data () {
        return {
             
        };
    },
    methods: {
        onQueryClick() {
            this.$emit('onQueryClick')
        },
        onCancelOperateClick() {
            this.$emit('onCancelOperateClick')
        }
    },
    created() {
        
    }
}
</script>
    
<style lang="css" scoped>
.dialog{
    position: relative;
}
.is-delete{
    width: 100%;
    text-align: center;
    font-size: 14px;
}
.is-delete:last-child{
    margin-top: 10px;
}
.vertical-center-modal /deep/ .ivu-modal-wrap{
    top: 0;
}
.vertical-center-modal /deep/ .ivu-modal{
    top: calc(50vh - 90px);
}
</style>