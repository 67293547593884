<template>
    <div>
        <!-- 热度   评分   最新 -->
        <div class="content-crumb" v-if="$route.path != '/Res/SunNewDetail'">
            <div>
                <span class='crumb-number'>共 {{total}} 份</span>
                <span style="cursor: pointer;" @click="hideShuoming">
                <img src="/static/images/res/sun-kczx/shuoming.png" title="" height="20px" alt="" v-show="showShuoming">
                <!-- wyp 2021/9/15 添加版本说明提示语 -->
                <span v-show="showShuoming" style="font-size: 12px;color: gray;margin-left: 8px;">展开查看版本说明</span>
                </span>
            </div>
            <div>
                <search-input ref="searchInput" @on-search='clickSearch'></search-input>
            </div>
        </div>
        <!-- 列表 -->
        <div class="content-list" :class="{listHeight:$route.path =='/Res/Tbkc'}">
            <div v-if='isValidArray(resList)' class="scrollLoad" v-infinite-scroll="onScrollLoad" :infinite-scroll-immediate="false" infinite-scroll-distance="50">
                <template >
                    <el-collapse-transition>
                        <!-- wyp 2021/9/15 去掉版本说明按钮显隐逻辑 -->
                        <div class="shuoming-box" :title="describe" v-if="isValid(describe)&&showDescribe">
                            <div>{{describe}}</div>
                            <i class="el-icon-close" @click="hideShuoming"></i>
                        </div>
                    </el-collapse-transition>
                    <sun-list-item
                        @on-share='onShare'
                        @on-delete='$emit("on-delete")'
                        @open-detail='clickName'
                        @open-shadow='clickName'
                        @collectClick="onCollectedClick"
                        @onAddBasketClick='onAddBasketClick'
                        @onDelBasketClick='onDelBasketClick'
                        @onBuzhiClick="onBuzhiClick"
                        @onPaperStaticClick="onPaperStaticClick"
                        class='list-box'
                        :filterType='data.scope'
                        :isShowOrigin='isShowOrigin'
                        :showCatalog="showCatalog"
                        v-for='(item, index) in resList' :key='item.Idx'
                        :item='item' 
                        :atid="atid"
                    ></sun-list-item>
                    <div class="noMoreData" v-if="hasScrollBar">无更多数据</div>
                </template>
            </div>
        </div>
        <template v-if="loadAccount().role.indexOf('教师') >= 0">
            <res-basket 
                ref="basket" 
                @open-detail='clickName' 
                :clearBasketList='clearBasketList' 
                :basketPageInfo="basketPageInfo" 
                :list="basketResList" 
                @updateCandidateData="updateCandidateData" 
                @deleteCandidateSuccess="deleteCandidateSuccess" 
                @onChangePage="onChangePage"
                @onBasketScroll="onBasketScroll"
                @onBasketIconMoved="onBasketIconMoved"
                >
            </res-basket>
            <div class="cart">
                <div class="ball-wrapper" v-for="(ball, index) of balls" :key="index">
                    <transition
                    name="drop"
                    @before-enter="beforeEnter"
                    @enter="enter"
                    @after-enter="afterEnter"
                    >
                    <div class="ball" v-show="ball.show">
                        <div class="inner inner-hook"><Icon type="ios-cart-outline" size="24"></Icon></div>
                    </div>
                    </transition>
                </div>
            </div>
        </template>
        <buzhi-modal v-if="showBuzhi" :info="modalInfo" @closeBuZhiModal="showBuzhi=false"></buzhi-modal>
        <paper-static-modal v-if="showPaperStatic" :info="modalInfo" @closePaperStaticModal="showPaperStatic=false"></paper-static-modal>
    </div>
</template>

<script>
import searchInput from '@/components/res/Common/Input/SearchInput.vue'
import SunListItem from '@/components/res/Common/List/SunListItem.vue'
import ResBasket from '@/components/res/Common/Layout/NewBasket.vue'
const BuzhiModal = resolve=> require.ensure([], () => resolve(require('./BuZhiModal')))
const PaperStaticModal = resolve=> require.ensure([], () => resolve(require('./PaperStaticModal')))
export default {
    components: {searchInput, SunListItem, ResBasket,BuzhiModal,PaperStaticModal},
    props: {
        list: {
            type: Array,
            default: []
        },
        data: {
            type: Object,
            default: {}
        },
        loading:{
            type:Boolean,
            default:false
        },
        showCatalog: {
            type: Boolean,
            default: false
        },
        isShowOrigin: {
            type: Boolean,
            default: true
        },
        isSunPlatform: {
            type: Boolean
        },
        label: {
            type: String
        },
        describe:{
            type: String
        },
        showDescribe:{
            type:Boolean,
            default: false
        },
        atid:{
            type:Number,
        },
    },
    data() {
        return {
            crumbIndex: 3,
            resList:null,
            crumbList: [
                {id: 3, name: '最新'},
                {id: 1, name: '热度'},
                {id: 2, name: '评分'},
            ],
            noresult:false,
            shareInfo:{},
            modal_S: {
                status: false
            },
            balls: [ //小球 设为3个
                {
                    show: false
                },
                // {
                //     show: false
                // },
                // {
                //     show: false
                // },
            ],
            dropBalls: [],
            clearBasketList: [],
            pageParam:{
                pageIndex:1,
                pageSize:10
            },
            basketPageInfo:{
                total:0,
                pageSize:1000,
                current:1
            },
            basketTotalPage:null,
            basketResList:[],
            keywords:'',//搜索框关键词
            showBuzhi:false,//是否显示布置弹窗
            modalInfo:null,//弹窗所需数据,
            showPaperStatic:false,//是否显示试卷分析
            showShuoming:true,//是否显示说明
            basketIconMoveDistanc:0,//资源篮图标移动的距离

        }
    },
    methods: {
        hideShuoming(){
            // wyp 2021/9/15 改变控制自身显隐切换=>切换弹框显隐
            this.showDescribe = !this.showDescribe
        },
        //加入资源篮的操作
        onAddBasketClick(item, e) {
            let el = e.target;
            if(item.TypeIdx == 0) {
                this.$Notice.warning({
                    title: '提示',
                    desc: '该资源不允许加入资源篮！',
                    duration: 2
                })
            } else {
                this.addAccountCandidateResource(item.Idx, item.TypeIdx, item, ()=>{
                    this.balls.forEach(ele => {
                        if (!ele.show) {
                            ele.show = true; // 当切换元素的display:block/none时，会触发vue的动画
                            ele.el = el; // 将触发点击事件的时候绑定到小球对象上，方便获取动画初始时的位置
                            this.dropBalls.push(ele); // 取一个小球加入动画队列
                            return;
                        }
                    });
                })
            }
        },
        // 删除已添加的内容操作
        onDelBasketClick(item){
            let param = []
            let ridx = item.Idx
            param.push(ridx)
            let api = this.getResourceCenterBusinessUrl()+ '/api/ResourceBasketService/BatchCancel'
            this.startLoading()
            let that = this
            this.$http.post(api, param).then((res) => {
                this.stopLoading()
                if (res.data.status.code == 200){
                    this.$set(item, 'IsCandidated', false)
                    this.getAccountCandidateResourceList(1)
                    this.$bus.emit('onCartDelSuccess',ridx)
                }
            })
        },
        beforeEnter(el) {
            let count = this.balls.length;
            while (count--) {
                // 将动画队列中的小球，依次处理
                let ball = this.balls[count];
                if (ball.show) {
                    // 点击对象
                    let rect = ball.el.getBoundingClientRect(); 
                    console.log(rect);
                    // 目标对象
                    let targetRect = $(".basket-icon")[0].getBoundingClientRect()
                    // 以目标元素作为原点， 当前点击对象的偏移量
                    let x = rect.left - targetRect.left - 10; // 需要偏移的x向距离
                    let y = rect.top - targetRect.top - 10; // 需要偏移的y向距离
                    el.style.display = ""; // 当前状态下，display值为none，将其置空。
                    // 这里需要注意了，小球飞入的动画分两个维度，X轴和Y轴，因此
                    // 球从目标元素移动到点击元素
                    el.style.webkitTransform = `translate3d(0px, ${y}px, 0px)`; // 浏览器适配
                    el.style.transform = `translate3d(0px, ${y}px, 0px)`;
                    // 接着将“inner-hook”X向移动至“+”号处，其实此时外层“ball”的X位置没有动，但因为背景色等等样式只应用于“inner-hook”上，因此，视觉效果上，这个小球是移动到了“+”号的位置
                    let inner = el.getElementsByClassName("inner-hook")[0];
                    inner.style.webkitTransform = `translate3d(${x}px, 0, 0)`;  //浏览器适配
                    inner.style.transform = `translate3d(${x}px, 0, 0)`;
                }
            }
        },
        enter(el) {
            let rf = el.offsetHeight; // 主动触发浏览器重绘
            el.style.webkitTransform = "translate3d(0, 0, 0)"; //接着将小球位置置为初始值，但css中设置了transition .8s,因此，动画效果就出来了
            el.style.transform = "translate3d(0, 0, 0)";
            let inner = el.getElementsByClassName("inner-hook")[0];
            inner.style.webkitTransform = "translate3d(0, 0, 0)";
            el.style.transform = "translate3d(0, 0, 0)";
        },
        afterEnter(el) {
            
            let ball = this.dropBalls.shift(); //结束后，将这个活动中的小球删除
            if (ball) {
                ball.show = false;
                el.style.display = "none"; // 并且将其设为不可见
                this.$refs.basket.transitionBoo = true
                setTimeout(() => {
                    this.$refs.basket.transitionBoo = false
                }, 300);
            }
        },
        onCollectedClick() {
            this.$emit('onCollectedClick')
        },
        // 资源加入资源篮
        addAccountCandidateResource(ridx, ptidx, item, callback) {
            let param = {resourceDataId:ridx}
            let api = this.getResourceCenterBusinessUrl()+'/api/ResourceBasketService/AddResourceBasketV2'
            this.startLoading()
            this.$http.post(api,param).then((res) => {
                this.stopLoading()
                if (res.data.status.code == 200){
                    this.$set(item, 'IsCandidated', true)
                    this.getAccountCandidateResourceList(1)
                    // this.basketResList.push(item)
                    // this.basketPageInfo.total++
                    callback && callback();
                    console.log('加入资源篮成功')
                    this.$bus.emit('onCartAddSuccess',ridx)
                }else{
                    return
                }
            })
        },
        // 资源篮页码改变
        onChangePage(index) {
            this.basketPageInfo.current = index
            this.getAccountCandidateResourceList(2)
        },
        // 资源篮上传资源成功回调
        updateCandidateData() {
            this.getAccountCandidateResourceList(3)
        },
        deleteCandidateSuccess(idx) {
            idx.forEach((item1,i)=>{
                for (let j = 0; j < this.basketResList.length; j++) {
                    const item2 = this.basketResList[j];
                    if(item1== item2.Idx){
                        this.basketResList.splice(j,1)
                    }
                }
            })
            this.basketPageInfo.total -= idx.lengthnew
            // this.getAccountCandidateResourceList(4)
            // this.$emit('deleteCandidateSuccess')
        },
        // 获取资源篮数据
        getAccountCandidateResourceList(from) {
            let api = this.getResourceCenterBusinessUrl()+'/api/ResourceBasketService/GetResourceBasketByUserId'
            let param = {
                pageIndex:this.basketPageInfo.current,
                pageSize:this.basketPageInfo.pageSize,
                resourceTag:{
                    subject: {
                        mustOr: [{
                                buildFilterType: "eq",
                                buildFilterVale: this.getSessionVal("sc_subject")
                            } ,{
                                buildFilterType: "notexists",
                                buildFilterVale: null
                            }]
                    }
                },
                sortList: {
                    createTime: "desc"
                }
            }
            let that = this
            this.startLoading()
            this.$http.post(api,param).then(res=>{
                this.stopLoading()
                if(res.data.data){
                    let data = res.data.data
                    data = this.toUpperCase(data)
                    for (let i = 0; i < data.ResourceData.length; i++) {
                        data.ResourceData[i] = this.toUpperCase(data.ResourceData[i])
                    }
                    let list = []
                    let total = {Total:data.Total}
                    let value = {Value:data.PageIndex}
                    this.basketTotalPage = data.TotalPage
                    list.push(total,value)
                    data.ResourceData.forEach(element => {
                        let obj = {
                            Name:element.ResourceDataName,
                            Idx:element.ResourceDataId,
                            IsCollected:element.IsCollected,
                            IsCandidated:element.IsCandidated,
                            IsExcellent:element.IsExcellent,
                            Url:element.Url,
                            TypeIdx:element.Rtid,
                            Summary:element.Summary

                        }
                        list.push(obj)
                    });
                    this.basketPageInfo.total = data.Total
                    this.basketPageInfo = {
                        total:data.Total,
                        current:data.PageIndex,
                        pageSize:data.PageSize
                    }
                    if(that.basketPageInfo.current>1){//滚动加载
                        let newList = list.slice(2)
                        newList = that.basketResList.concat(newList)
                        for (let i = 0; i < newList.length; i++) {//滚动分页时去重
                            const last = newList[i];
                            for (let j = i+1; j < newList.length; j++) {
                                const next = newList[j];
                                if(last.Idx == next.Idx){
                                    newList.splice(j,1)
                                    j--
                                }
                            }
                        }
                        that.basketResList = newList
                    }else{
                        that.basketResList = list
                    }
                }else{
                    this.basketResList = [
                        {Total:0},
                        {Value:1}
                    ]
                    if(this.isValid(this.$refs.basket)) {
                        this.$refs.basket.list = []
                    }
                }
                

            })
        },

        clickName(item) {
            if(!item.Summary){
                this.$emit('open-shadow', item)
            }else{
                //改为直接跳转路由
                let id = item.Summary.id.id
                let version = item.Summary.id.version
                let paperSource = item.Summary.paperSource
                let param = {
                    "module": "papermanual",
                    "papersource": paperSource,
                    "paperid": id,
                    "contenttype": 1,
                    "paperversion": version,
                    "ispersonalized": this.$route.query.ispersonalized,
                    "seriesid": this.$route.query.id,
                    "seriesname": this.$route.query.title
                }
                let url = this.getZznScJumpUrl(param)
                window.location.href = url
            }
        },
        clickSearch(keywords) {
            console.log(keywords);
            this.keywords = keywords
            this.init()
            // if(keywords == '')return
            // this.$emit('on-search',keywords)
        },
        changePage(index) {
            this.$emit('on-change-page', index)
        },
        clickCrumb(id) {
            this.crumbIndex = id
            this.$emit('on-change-crumb', id)
        },
        changejx(bool) {
            this.$emit('on-change-jx', bool)
        },
        onShare(info){
            this.shareInfo = info
            this.$refs.modalShare.getSharedList()
            this.modal_S.status = true
        },
        init(){
            if(this.isValidArray(this.list)){
                if(this.list.length == 2){
                    this.noresult = true
                    this.resList = null
                }else{
                    this.noresult = false
                    let list = this.list.slice(2)
                    if(this.keywords.length>0){
                        this.resList = list.filter(item => {
                            return item.Name.match(this.keywords)
                        })
                    }else{
                        this.resList = list
                    }
                }
            }else{

            }
        },
        onScrollLoad(){
            console.log('onScrollLoad');
            this.$emit('onScrollBottom')
        },
        onBasketScroll(){
            if(this.basketPageInfo.current == this.basketTotalPage)return
            this.basketPageInfo.current++
            this.getAccountCandidateResourceList(5)
        },
        onBuzhiClick(info){
            this.showBuzhi = true
            this.modalInfo = info
        },
        onPaperStaticClick(info){
            this.showPaperStatic = true
            this.modalInfo = info
        },
        //获取图标移动的距离（修复动画bug用）
        onBasketIconMoved(distance){
            console.log(distance);
            this.basketIconMoveDistanc = distance
        }
    },
    computed: {
        total() {
            if(this.list.length) {
                let num = this.list[0].Total
                if(this.keywords !=''){
                    num = this.resList.length
                }
                return parseInt(num)
            } else {
                this.total = 0
            }
        },
        isSunPlatform() {
            let isSun = false
            let Platform = this.$store.state.platform
            if(this.isValid(Platform)) {
                let platformNumber = Platform.PlatformSystemNumber
                isSun = platformNumber == '000012'
            }
            return isSun
        },
        hasScrollBar(){
            if($('.scrollLoad').length>0){
                return $('.scrollLoad')[0].scrollHeight > $('.scrollLoad')[0].clientHeight
            }else{
                return false
            }
        },
    },
    watch:{
        list(nVal){
            this.keywords = ''
            this.init()
        },
        data: {
            handler() {
                if(this.data && this.isValid(this.data.order)) {
                    this.crumbIndex = this.data.order
                } else {
                    this.crumbIndex.order = 3
                }
            },  
            deep: true
        },
        describe:{
            handler:function(nVal){
                if(nVal){
                    let huanjie = $('.class-line').first().find('.menu-active').text()
                    if(huanjie == ' 课后作业 '&&localStorage.isBigUnit!=1){
                        // wyp 2021/9/15 有说明
                        // this.showDescribe = true
                        this.showShuoming=true;
                    }else{
                        // wyp 2021/9/15 无说明
                        // this.showDescribe = false
                        this.showShuoming=false;
                    }
                }else{
                    // wyp 2021/9/15 无说明
                    this.showShuoming=false;
                }
            },
            immediate:true
        }
    },
    created(){
        this.init()
        if(this.loadAccount().role == '教师') {
            this.getAccountCandidateResourceList(6)
        }
    },
    mounted() {
        // if(this.loadAccount().role == '教师') {
        //     let that = this
        //     window.refreshClientWeb = function () {
        //         that.getAccountCandidateResourceList()
        //     };
        // }
    }
}
</script>

<style scoped>
.crumb-number{
    font-size: 14px;
    color: #4A4A4A;
    /* color: #1B2A51; */
    margin-right: 20px;
}
.content-crumb{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0 0px 22px;
    height: 56px;
}
.no-result-img{
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 450px;
}

.ball {
    position: fixed;
    z-index: 100;
    right: 1%;
    bottom: 25%;
}
.drop-enter-active{
    transition: all 0.8s cubic-bezier(0.49, -0.29, 0.75, 0.41);
}
.inner {
    width: 28px;
    height: 28px;
    color: #4597f7;
    font-weight: bold;
    transition: all 0.8s;
}
.phone-no-result{
    width: 100%;
}
.content-list{
    /* overflow-y: scroll; */
    height: calc(100vh - 168px);
}
.scrollLoad{
    overflow: auto;
    height: 100%;
}
.scrollLoad::-webkit-scrollbar{
    width: 4px;
}
.scrollLoad::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #d8d8d8;
}
.noMoreData{
    height: 50px;
    line-height: 50px;
    text-align: center;
}
.listHeight{
    height: calc(100vh - 188px);
}
.shuoming-box{
    margin-bottom: 15px;
    background: #FFF;
    padding: 20px 20px 0 20px;
    font-size: 14px;
    color: #757575;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* -webkit-line-clamp: 10; */
    -webkit-box-orient: vertical;
    white-space:pre-wrap;
    position: relative;
}
.shuoming-box div{
    margin-bottom: 20px;
}
.shuoming-box i{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 22px;
    cursor: pointer;
}
.content-crumb img{
    cursor: pointer;
}
</style>