<!--
 * @Author: your name
 * @Date: 2021-03-16 09:46:19
 * @LastEditTime: 2021-06-22 15:58:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sun_highschool\src\components\res\Common\Input\SearchInput.vue
-->
<template>
    <div>
        <div class="input-box" :style='inputBorder'>
            <Icon class='icon' :size='25' :color='searchBtnColor' type="ios-search-strong" @click.native='search'></Icon>
            <input ref='input' :style='inputWidth' v-model='value' type='text' @keyup.enter='search' :placeHolder='placeHolder'>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        placeHolder: {
             default: '请输入名称'
        },
        border: {
            default: '#D4DFE7'
        },
        width: {
            default: '220'
        }
    },
    data() {
        return {
            value: '',
            searchBtnColor: '#10BED9'
        }
    },
    methods: {
        search(event) {
            // let str = this.value.replace(/\s*/g,"")
            this.$emit('on-search', this.value)
        },
        clearKeyword() {
            this.value = ''
        }
    },
    watch:{
        value(){
            this.search(this.value)
        }
    },
    computed: {
        inputBorder() {
            let borderColor = this.border
            return "border: 1px solid" + borderColor
        },
        inputWidth() {
            let width = this.width
            return "width:"+ width +"px"
        },
        isSunPlatform() {
            let isSun = false
            let Platform = this.$store.state.platform
            if(this.isValid(Platform)) {
                let platformNumber = Platform.PlatformSystemNumber
                isSun = platformNumber == '000012'
            }
            return isSun
        },
    },
    created(){
        if(this.loadAccount().sourceid == 17){
            this.searchBtnColor = '#17C73E'
        }
        if(this.isSunPlatform) {
            this.searchBtnColor = '#39C5FF'
        }
    }
}
</script>

<style scoped>
    .input-box{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px 10px 3px 10px;
        background: #fff;
        border-radius: 32px !important;
    }
    input{
        width: 220px;
        border:none;
        outline:none;
        color:#9B9B9B;
    }
    .icon{
        color:#9B9B9B !important ;
        cursor: pointer;
        font-size: 20px !important;
        margin-right: 5px;
    }
</style>
