<!--
 * @Author: your name
 * @Date: 2021-04-07 15:51:23
 * @LastEditTime: 2021-05-12 20:18:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \test\src\components\res\Common\List\ResListStu.vue
-->
<template>
  <div class='ResListStu'>
        <div class="res-box" v-infinite-scroll="onScrollLoad" :infinite-scroll-immediate="false">
            <div class="res-item" @click="clickItem(item)" v-for="(item,index) in resList" :key="index">
                <div class="tuijian-icon" v-if="item.IsExcellent"></div>
                <div class="cover">
                    <list-cover class="icon" :url='item.Url' :info='item'></list-cover>
                </div>
                <p class="title" :title="item.Name">{{item.Name}}</p>
            </div>
        </div>
  </div>
</template>
<script>
import listCover from '@/components/res/Common/List/ListCover.vue'
export default {
  name: 'ResListStu',
  props:['list'],
  components: {listCover},
  data () {
    return {

    }
  },
  computed: {
      resList(){
          return this.list.slice(2)
      }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    onScrollLoad(){
        console.log('onScrollBottom');
        this.$emit('onScrollBottom')
    },
    clickItem(item) {
        this.$emit('open-shadow', item)
    }
  }
}
</script>

<style lang='less' scoped>
.res-box{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    height: 90vh;
    overflow: auto;
    align-content: flex-start;
    .res-item{
        position: relative;
       width: calc((100% - 30px) / 4);
       background: #FFF; 
       height: 180px;
       margin-bottom: 10px;
       cursor: pointer;
       .cover{
           height: 120px;
           display: flex;
           align-items: center;
           border-bottom: 1px solid #EEEEEE;
           .icon{
               margin:0 auto;
               width: 60px;
               height: 60px;
           }
        }
        .title{
            height: 60px;
            padding:0 16px;
            font-size: 14px;
            color: #4A4A4A;
            overflow: hidden;
            line-height: 30px;
            text-overflow: ellipsis;
            -webkit-line-clamp:2;
            -webkit-box-orient:vertical;    
            display: -webkit-box;
            // display: flex;
            // align-items: center;
        }
    }
    .res-item:not(:nth-child(4n)){
        margin-right: 10px;
    }
    .tuijian-icon{
        background-image: url('/static/images/res/sun-kczx/kczx-tuijian.png');
        min-width: 40px;
        width: 40px;
        height: 40px;
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
    }
}
</style>
