<template>
    <div class="">
        <img :src="'/static/images/res/icon/' + cover" alt="">
    </div>
</template>

<script>
export default {
    props: ['url','ext','info'],
    methods:{
        getAttachFileUrl(url){ // url处理
            let ret = "";
            if (this.isValidArray(url)) {
                let items = url.split('|');
                let params = "";
                if (this.isValidArray(items) && items.length >= 3) {
                    ret = this.getDownloadServer() + "AttachFiles/" + items[2] + "/" + encodeURI(items[1]);
                } else {
                    ret = url;
                }
            }
            return $.trim(ret);
        },
        getDownloadServer(){
            let acnt = this.loadAccount();
            return this.isValid(acnt) && this.isValidArray(acnt.dlserver) ? acnt.dlserver : "/";
        }
    },
    computed: {
        cover: function() {
            let type = '', isDtk = false, typeIdx = ''
            if(this.isValid(this.info)) {
                if(this.isValidArray(this.info.RSource)) {
                    isDtk = this.info.RSource[0].is_paper_dtk
                }
                typeIdx = this.info.TypeIdx
            }
            let name_idx = this.info.Name.lastIndexOf('.')
            let name_suffix = this.info.Name.slice(name_idx + 1)
            // 数字试题
            if(typeIdx && typeIdx == 0) {
                return 'szst.png'
            }
            // 数字试卷
            if(typeIdx == 1 || typeIdx == 2) {
                if(this.isValid(this.info.res_type) && this.info.res_type == 'sc' && name_suffix != 'dcf') {
                    return 'icon_resimg_sunclass_paper3.png'
                }
                // 是否是答题卡
                if(isDtk) {
                    return 'dtk.png'
                }
                return 'szsj.png'
            }
            // 教研资源
            if(typeIdx == 8) {
                return 'jy.png'
            }
            // 学习设计
            if(typeIdx == 11) {
                return 'design.png'
            }
            if( (this.isValid(this.url) && this.url != "")){ //非数字试卷
                let url = this.getAttachFileUrl(this.url)
                if(url.indexOf('|') == -1){
                    let name = url.split('/')
                    let tempArr = name[name.length - 1].split('.')
                    if(this.isValid(name) && this.isValidArray(tempArr) && tempArr.length > 1){
                        let tempData = name[name.length - 1].split('.');
                        type = tempData[tempData.length - 1].toLocaleLowerCase()  // 这块有问题
                        if(this.isValid(this.info.res_type) && this.info.res_type == 'sc' && type == 'dcf') {
                            // return 'icon_resimg_sunclass_paper3.png'
                            return 'dcf.png'
                        }
                    }else{
                        return 'otherfile.png'
                    }
                }else{
                    return 'otherfile.png'
                }
            } else if(this.isValid(this.ext)){
                type = this.ext
            }else if(this.isValid(this.info)){
                let name = this.info.Name
                if(this.isValid(this.info.res_type) && this.info.res_type == 'sc' && name_suffix != 'dcf') {
                    return 'icon_resimg_sunclass_paper3.png'
                } else {
                    if(this.isValid(name) && name != ''){
                        type = $.trim(name.substring(name.lastIndexOf('.') + 1))
                    }
                }
            }
            if (type == 'wmv' || type == 'mp4' || type == 'asf' || type == 'avi' || type == 'flv' || type == 'mkv' || type == 'rm' || type == 'rmvb'  || type == '3gp' || type == 'dat' || type == 'mpg' || type == 'mov') {
                return 'video.png'
            } else if (type == 'jpg' || type == 'jpeg' || type == 'png' || type == 'gif' || type == 'bmp' || type == 'pad') {
                return 'png.png'
            } else if (type == 'mid' || type == 'mp3' || type == 'wav' || type == 'wma' || type == 'ra' || type == 'ogg' || type == 'flac' || type == 'ape' || type == 'm4a') {
                return 'mp3.png'
            } else if (type == 'doc' || type == 'docx') {
                return 'word.png'
            } else if (type == 'xls' || type == 'xlsx') {
                return 'xls.png'
            } else if (type == 'ppt' || type == 'pptx' || type == 'pps' || type == 'ppsx') {
                return 'PPT.png'
            } else if (type == 'zip' || type == 'rar' || type == 'cab' || type == '7z') {
                return 'zip.png'
            } else if (type == 'html' || type == 'htm' || type == 'xml' || type == 'mht') {
                return 'h5.png'
            } else if (type == 'flash' || type == 'swf') {
                return 'flash.png'
            } else if (type == 'pdf') {
                return 'PDF.png'
            } else if (type == 'rtf' || type == 'txt') {
                return 'TXT.png'
            } else if (type == 'apk') {
                return 'apk.png'
            } else if (type == 'exe') {
                return 'exe.png'
            } else if (type == 'svg' || type == 'fcs') {
                return 'svg.png'
            } else if (type == 'dcf') {
                return 'dcf.png'
            } else if (type == 'dct') {
                return 'dct.png'
            } else if (type == 'upl') {
                return 'upl.png'
            } else if (type == 'yoya') {
                return 'yoya.png'
            }else {
                return 'otherfile.png'
            }
        }
    }
}
</script>

<style scoped>
</style>
