<!--
 * @Author: your name
 * @Date: 2021-03-19 09:36:49
 * @LastEditTime: 2021-10-20 15:25:33
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \sun_highschool\src\components\res\Common\Icon\SunIconEdit.vue
-->
<template>
  <div class='SunIconEdit'>
        <div class="box-options">
            <template v-for="(item,index) in optionList" >
                <div class="warp" 
                    @click="onBtnClick(item.text)" 
                    :key="index"
                    v-if="showBtn(item)"
                >
                    <div 
                        class="options" 
                        :class="[item.btnClass,{isActive:isCollected&&item.text == '收藏'}]"
                    ></div>
                    <div class="text">{{item.text}}</div>
                </div>
            </template>
            <div class="addToCart" >
                <div class="warp" @click="onBtnClick('资源篮',$event)" :class="{disabled:isCandidated}">
                    <i :class="isCandidated?'el-icon-remove':'el-icon-plus'"></i>
                    <!-- <span class="delCandidated" v-show="isCandidated"></span> -->
                    <span ref="cartWarp">资源篮</span>
                </div>
            </div>
        </div>
  </div>
</template>
<script>
export default {
    name: 'SunIconEdit',
    props:['info'],
    components: {},
    data () {
        return {
            optionList:[
                {btnClass:'sjfx',text:'试卷分析'},
                {btnClass:'sc',text:'收藏',isActive:true},
                {btnClass:'bz',text:'布置'},
                {btnClass:'xz',text:'下载'},
            ],
            isCollected:false,
            isCandidated:false,
            // 不允许下载的文件类型
            notDownloadType:['m4a','amr','mp3','wma','3gp','wmv','mpeg','mov','mp4','rmvb','qsv','flv','avi']
        }
    },
    computed: {
    },
    watch: {
        info(nVal,oVal){
            if(nVal){
                this.isCollected = nVal.IsCollected
            }
        }
    },
    created () {
        this.setCollected = this.throttle(this.setCollected,200)//防抖
        let that = this
        this.$bus.on('onCartDelSuccess',function(id){
            if(that.info.Idx == id){
                that.isCandidated = false
            }
        })
        this.$bus.on('onCartAddSuccess',function(id){
            if(that.info.Idx == id){
                that.isCandidated = true
            }
        })
        this.$bus.on('onCartCleared',function(id){
            that.isCandidated = false
        })
    },
    mounted () {
        this.isCollected = this.info.IsCollected
        this.isCandidated = this.info.IsCandidated
    },
    methods: {
        onBtnClick(name,e){
            switch(name){
                case '试卷分析':
                    this.$emit('onPaperStaticClick',this.info)
                    break;
                case '收藏':
                    this.setCollected()
                    break;
                case '布置':
                    this.info.isPersonalized = this.$route.query.ispersonalized + '' == 'true';
                    this.info.seriesId = this.$route.query.id;
                    this.info.seriesName = this.$route.query.title;
                    
                    this.$emit('onBuzhiClick',this.info)
                    break;
                case '下载':
                    this.downloadRes()
                    break;
                case '资源篮':
                    if(e.target.className == 'el-icon-plus'){
                        e.target.parentNode.click()
                        return
                    }
                    if(this.isCandidated){
                        this.$emit('onDelBasketClick', this.info)
                    }else{
                        this.$emit('onAddBasketClick', this.info, e)
                    }
                    break;
            }
        },
        setCollected(){
            let that = this
            let param = {resourceDataId:this.info.Idx}
            let api = ''
            if(this.isCollected){
                api = '/api/FavoritesService/CancelFavorites'
            }else{
                api = '/api/FavoritesService/AddFavorites'
            }
            this.startLoading()
            this.$http.post(this.getResourceCenterBusinessUrl()+api, param).then((res) => {
                this.stopLoading()
                if (res.data.status.code == 200){
                    that.isCollected = !that.isCollected
                }else{
                    return
                }
            })
        },
        // 下载
        downloadRes(){
            let url = decodeURIComponent(this.info.Url || '');
            this.getAccountDownloadPermission(url,this.info.Name,this.info.Idx)
        },
        getAccountDownloadPermission(url, name) {
            let device = this.getSessionVal('device')
            if(this.isValid(device) && device != '') {
                if(device == 'forclasswin' || device == 'sunclass'){
                    let arr = [{
                        'name': name,
                        'url': url,
                        'source': null,
                        'size': null,
                        'dataid': 0
                    }]
                    this.sendCPPMessage(arr, 'SunWinBatch', null)
                }else if(device == 'androidPadCKL'){
                    let downloadList = [{
                        subject: '',
                        url: url,
                        fleName: name,
                        encryption: 0,
                        catalog: ''
                    }]
                    let param = {
                        cmd: 'webPageResDownload',
                        list: downloadList
                    }
                    HostApp.alert(param)
                }
            } else {
                window.location.href = url
            }
        },
        showBtn(item){
            if(item.text == '下载'){
                return this.getResType(this.info)
            }else if(item.text == '试卷分析'){
                if(this.info.Atid == 69 || this.info.Atid == 100){
                    return false
                }
                if(this.info.TypeIdx != '3'){
                    return true
                }else{
                    return false
                }
            }else if(item.text == '布置'){
                if(this.info.Atid == 69 || this.info.Atid == 100){
                    return false
                }
                if(this.info.TypeIdx != '3'){
                    return true
                }else{
                    return false
                }
            }  
            return true
        },
        getResType(info){
            if(info.TypeIdx != 3) return false
            if(info.Url){
                let type = info.Url.split('.')
                type = type[type.length-1].toLowerCase()
                if(this.notDownloadType.indexOf(type)!=-1){
                    return false
                }else{
                    return true
                }
            }else{
                return true
            }
        },

    }
}
</script>

<style lang='less' scoped>
.box-options{
    line-height: 70px;
    display: flex;
    justify-content: flex-end;
    max-width: 400px;
}
.box-options .warp{
    display: flex;
    align-items: center;
    margin-right: 20px;
    cursor: pointer;
}
.box-options .options{
    display: inline-block;
    width: 22px;
    height: 22px;
    background-size: 96px;
    background-position-x: -5px;
    background-image:url('/static/images/res/sun-kczx/kczx-option.png') ;
    margin-right: 5px;
}
.box-options .text{
    white-space:nowrap;
}
.box-options .sjfx{
    background-position-y: -4px;
}
.box-options .sc{
    background-position-y: 136px;
}
.box-options .bz{
    background-position-y: 47px;
}
.box-options .xz{
    background-position-y: 25px;
}
.box-options .isActive{
    background-position-x: 48px;
}
.box-options .addToCart{
    display: flex;
    align-items: center;
}
.box-options .addToCart .warp{
    height: 30px;
    width: 100px;
    // background-color:#2DCCC4;
    // color: #FFF;
    // padding: 0 18px;
    color: #757575;
}
.box-options .addToCart .warp span{
    color: #000;
}
.disabled{
    // background-color: #c0c4cc !important; 
    // cursor: not-allowed !important;
    background-color: #FFF !important;
    color: #2DCCC4 !important;
    // border: 1px solid #2DCCC4;
}
.delCandidated{
    // background-image:url('/static/images/res/sun-kczx/buket-del.png') ;
    width: 14px;
    height: 14px;
    background-size: 100%;
    margin-right: 5px;
    color: #2DCCC4 !important;
}
.box-options .addToCart .warp i{
    margin-right: 3px;
    // font-weight: 700;
    font-size: 20px;
    margin-right: 5px;
}
.buzhi-box{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 998;
    background: rgba(255,255,255,0);
    left: 0;
}
.box-options .addToCart .warp:hover{
    color: #2DCCC4 !important;
}
.box-options .addToCart .warp:hover span{
    color: #2DCCC4 !important;
}
.warp:hover{
    color: #2DCCC4;
}
.warp:hover .options{
    background-position-x: 48px;
}
</style>
