<template>
    <div>
        <div class="label-container" v-if="isValid(item.ResourceIndex) && isValidArray(item.ResourceIndex.ChildList) && isShowLabel" :class="{ 'show-all-label': showAll, 'show-line': !showAll}">
            <div v-for="(items, idx) in item.ResourceIndex.ChildList" :key="idx" :class="{'label-div': !showAll}">
                <div v-if="items.ChildList">
                    <div class="knowledge-item">
                        <span class="knowledge-name" v-if="idx == 0">{{ items.Name.slice(0, 3) }}：</span>
                        <span v-else class="knowledge-name">{{ items.Name }}</span>
                        <span class="name-label" v-for="(it, i) in items.ChildList" :key="i">
                            <span v-if="i != 0">，</span>
                            <span v-if="isValid(it.point_name) && it.point_name != ''">{{ it.point_name | pointFilter }}（{{ it.attainment_name + ',' + it.cognitive_name }}）</span>
                            <span v-else>{{ it.name }}</span>
                        </span>
                    </div>
                    <div v-if="!showAll && items.Idx != labelLength" class="label-sperate">/</div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
export default {
    name: "",
    props: {
        item: {
            type: Object
        },
        showAll: {
            type: Boolean
        }
    },
    data () {
        return {
             
        };
    },
    filters: {
        pointFilter(str) {
            let idx = str.lastIndexOf('》')
            return str.slice(idx + 1)
        }
    },
    computed: {
        isShowLabel() {
            let flag = false
            let arr = this.item.ResourceIndex.ChildList
            if(this.isValidArray(arr)) {
                for(let prop of arr) {
                    if(this.isValidArray(prop.ChildList)) {
                        flag = true
                        break
                    }
                }
            } else {
                flag = false
            }
            return flag
        },
        labelLength() {
            if(this.isValid(this.item.ResourceIndex) && this.isValidArray(this.item.ResourceIndex.ChildList)) {
                let arr = this.item.ResourceIndex.ChildList
                let arr1 = arr.filter((ele) => {
                    return this.isValidArray(ele.ChildList)
                })
                return arr1[arr1.length - 1].Idx
            } else {
                return 0
            }
        }
    }
}
</script>
    
<style lang="css" scoped>
    .label-container{
        width: 100%;
        cursor: pointer;
        color: #9c9898;
    }
    
    .label-container .ivu-breadcrumb>span:last-child{
        color: #999;
        font-weight: normal;
    }
    .label-sperate{
        margin: 0 10px;
    }
    .show-line{
        height: 24px;
        color: #9c9898;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .knowledge-item, .other-item, .label-div, .label-sperate{
        display: inline-block;
    }
</style>